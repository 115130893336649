import { default as React, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TextInput from "src/components/inputs/Text/TextInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import AutocompleteClara from "src/modules/generic/components/Inputs/Autocomplete";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import { Constants } from "src/v1/utils";
import _ from "lodash";
import { useLD } from "src/providers/LDProvider";
import { is } from "date-fns/locale";

function ReviewCorporateStakeholderSignatoryStep() {
  const { next, prev, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const ldProvider = useLD();
  const isPaymentProcessingRemoved =
    ldProvider.featureFlags["payment-process-removal-pincas-and-renewals"];

  const initialValues =
    context?.ReviewSignatory ?? context.initialData.authorizedSignatory;

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    dispatch(
      addAssistantText(
        isPaymentProcessingRemoved ?
          translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.TOOLTIP_PAYMENT_REMOVAL"
          ) :
          translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.TOOLTIP"
          ),
        "alert"
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.SIGNATORY.TOOLTIP"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    stakeholder: Yup.object().nullable().required("This field is required"),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: "This must be a valid email.",
        excludeEmptyString: true,
      })
      .nullable()
      .required("This field is required"),
    phoneNumber: Yup.string()
      .required("This field is required")
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    signingAuthority: Yup.string().required("This field is required"),
    position: Yup.string().required("This field is required"),
  });

  const stakeholdersList = context?.initialData?.stakeholder?.directors.map(
    (director) => {
      return { value: director, label: director.fullName || "" };
    }
  );

  const stakeholderName: string = context.initialData.stakeholder.fullName;

  const selectedStakeholderId = initialValues?.stakeholder;

  const selectedStakeholder = _.find(
    stakeholdersList,
    (stakeholder) => stakeholder.value.id === selectedStakeholderId
  );

  const myDefaultValues = {
    ...initialValues,
    stakeholder: selectedStakeholder?.value || {
      fullName: initialValues?.fullName,
    },
  };

  return (
    <Form
      defaultValues={myDefaultValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.SIGNATORY.TITLE"
          ),
          subTitle: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.SUBTITLE",
            { stakeholderName }
          ),
          skeleton: null,
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>{translate("BUTTON_NEXT")}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.FULL_NAME_LABEL"
            )}
          >
            <ControllerInput
              render={AutocompleteClara}
              name="stakeholder"
              options={stakeholdersList}
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.FULL_NAME_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.EMAIL_LABEL"
            )}
          >
            <ControllerInput
              render={TextInput}
              name="email"
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.EMAIL_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.TELEPHONE_NUMBER_LABEL"
            )}
          >
            <ControllerInput
              render={PhoneInput}
              disableDropdown={false}
              name="phoneNumber"
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.TELEPHONE_NUMBER_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.SIGNING_AUTHORITY_LABEL"
            )}
          >
            <ControllerInput
              render={TextInput}
              name="signingAuthority"
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.SIGNING_AUTHORITY_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.ROLE_LABEL"
            )}
          >
            <ControllerInput
              render={TextInput}
              name="position"
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.ROLE_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCorporateStakeholderSignatoryStep;
